.contact {
  margin: 100px auto;
}

.contact h3 {
  margin-bottom: 15px;
}

.contact p {
  font-size: 18px;
  line-height: 1.8;
}

.contact .flavor-image {
  max-width: 70%;
  margin: 40px auto;
  display: block;
}

.contact-map {
  height: 40vh;
  width: 40vw;
  margin: 1em 0;
  border-radius: 0.5em;
}

@media (max-width: 768px) {
  .contact-map {
    height: 40vh;
    width: 90vw;
    margin: 1em 0;
    border-radius: 0.5em;
  }
}

.conact-info-container {
  address {
    margin-bottom: 2rem;
    p {
      margin-bottom: 0 !important;
    }
  }
  h5 {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  }
  p {
    margin-bottom: 0;
  }

  a {
    color:black;
    p {
      margin-bottom: 2rem;
    } 
  }
}