.cloud-stories {
  margin-bottom: 60px;
  .card {
      border: none;
      @media (max-width: 768px) {
          margin-bottom: 30px;
      }
  }
  .card-img-top {
      border-bottom: 2px solid $red;
  }
  .card-body {
      position: relative;
      padding-left: 2.25rem;
      &:before {
          content: "\A";
          position: absolute;
          top: 0;
          left: 0;
          background-color: $red;
          width: 0;
          height: 0;
          border-top: 20px solid $red;
          border-left: 20px solid white;
      }
      .card-title {
          margin-bottom: 90px;
          font-weight: bold;
      }
      a.button {
          position: absolute;
          right: 2rem;
          bottom: 2rem;
      }
  }

  // For when they're in the list e.g. Case Studies page
  &.list {
    .col-md-4 {
      margin-bottom: 80px;
    }
    .card-body {
      padding-bottom: 100px;
    }
    .card-title {
      margin-bottom: 0;
    }
  }
}