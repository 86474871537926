// Libs
@import "~bootstrap/scss/bootstrap";

// Settings
@import './scss/global.scss';

// Elements
@import './scss/elements/jumbotron';
@import './scss/elements/cards';
@import './scss/layouts/footer';
@import './scss/layouts/header';

// Pages
@import './scss/layouts/homepage';
@import './scss/layouts/about';
@import './scss/layouts/services';
@import './scss/layouts/cloudMigration';
@import './scss/layouts/managedServices';
@import './scss/layouts/contact';
@import './scss/layouts/applicationDevelopment';
@import './scss/layouts/caseStudies';

