// Cloud stories
.cloud-stories-about {
    font-size: 26px;
    margin: 100px auto;
    text-align: center;
}

// Feld
.large-red-jumbotron {
    margin: 0px auto 100px 0;
    h2, h3 {
        color: white;
        margin-bottom: 0;
        font-weight: normal;
    }
    h3 {
        line-height: 1.5;
    }
    .author {
        color: white;
        font-size: 24px;
    }
}

.feld-logo {
    @media (min-width: 931px) {
        margin-left: 100px;
        width: 400px; 
    }
}

.hgt-end-result--h4 {
    line-height: 2.5rem;
}

.hgt-end-result--h3 {
    font-size: 2.25rem !important;
    margin-bottom: 25px;
}