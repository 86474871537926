.cloud-migration-about {
    margin: 100px auto;
}

.cloud-migration.side-by-side {
    margin-bottom: 100px;
    h3 {
        font-size: 35px;
    }
    h4 {
        font-weight: normal;
        margin-bottom: 20px;
    }
    h5 {
        font-size: 35px;
        margin: 40px 0 20px 0;
    }
}