.managed-services-about {
  max-width: 75%;
  margin: 100px 0;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  .row {
    margin-top: 30px;
  }
}

.managed-services-cta {
  max-width: 90%;
  margin: 100px auto;
  background-color: $pink;
  h2 {
    color: white !important;
    margin-bottom: 0;
  }
}

.image-grid {
  margin-top: 100px;
  .item {
    margin-bottom: 54px;
    padding: 0 40px;
    img {
      margin-bottom: 20px;
      height: 50px;
      width: auto;
    }
    h5 {
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
