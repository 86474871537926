.homepage-what-we-do {
    .col-md-3, .col-md-4 {
        display: flex;
        p {
            margin: auto;
        }
    }
    margin-bottom: 60px;
    p {
        color: $gray;
        font-size: 16pt;
    }
    .col-md-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
        }
    }
    h2 {
        margin-bottom: 30px;
    }
    @media(max-width: 768px) {
        * {
            // text-align: center !important;
        }
        img {
            margin: 20px 0;
        }
    }
}

.homepage-dream-cloud {
    margin-bottom: 60px;
    color: white;
    .jumbotron {
        background-image: url("/img/homepage/dreamcloud.jpg");
        background-size: cover;
    }
    .w-50 {
        display: inline-block;
        &.left {
            float: left;
        }
    }
    h4 {
        margin-bottom: 30px;
        font-size: 1.9rem;
    }
    .button {
        background-color: $pink;
    }
    @media (max-width: 768px) {
        .w-50 {
            width: 100% !important;
        }
        h4 {
            margin: 30px;
        }
        .button {
            display: inline-block;
        }
        * {
            float: none;
            text-align: center;
        }
    }
}

.homepage-how-can-we-help {
    margin-bottom: 60px;
    .inverse {
        background-color: $pink;
        color: white;
        .button:hover {
            background-color: white;
            color: $red;
        }
    }
    .card {
        border-radius: 0;
        .card-body {
            padding: 2rem 2rem 2rem 4rem;
            .card-title {
                margin-bottom: 70px;
            }
            a.button {
                position: absolute;
                right: 0.1rem;
                bottom: 2rem;
            }
        }
    }
}