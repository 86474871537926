header {
  padding: 12px 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  #navrow > .dropdown {
    position: absolute;
  }
  .nav-bg {
    background: radial-gradient(
      circle,
      rgba(13, 20, 30, 1) 0%,
      rgba(18, 41, 71, 1) 35%,
      rgba(13, 20, 30, 1) 100%
    );
    transition: opacity 250ms linear;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    pointer-events: none;
  }
  .logo {
    margin-left: 3rem;
    img {
      height: 25px;
    }
  }
  .logo {
    cursor: pointer;
    position: absolute;
  }
  .nav {
    width: 100%;
    a:hover {
      background-color: $pink;
      color: white !important;
    }
    .circle a:hover {
      background-color: transparent;
    }
    .nav-item a {
      color: white;
    }
    .nav--contact {
      border: 2px solid white;
      margin-left: 25px;
      padding: 5px 15px 5px 15px;
    }
  }
  .logo,
  img {
    transition: none;
  }

  // Dropdowns
  .dropdown-item {
    color: black !important;
  }
  .show .dropdown-toggle {
    background-color: unset !important;
    border-radius: 0 !important;
    width: 100%;
    text-align: left;
    &:hover {
      color: white !important;
    }
  }
  .dropdown-toggle {
    border: unset;
    background-color: unset;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    &:hover {
      background-color: $pink !important;
    }
    &:focus {
      background-color: unset;
      border: unset;
      box-shadow: unset;
    }
  }
  .dropdown-menu.show {
    transform: translate(0px, 40px) !important;
  }
  

  // Mobile
  @media (max-width: 1200px) {
    padding: 20px 0;
    .justify-content-end.nav {
      display: none;
    }
    .logo {
      margin-left: 0;
      left: 50%;
      transform: translate(-50%, 0);
      img {
        max-height: 15px;
      }
    }
    svg {
      left: 10px;
    }
  }

  // When the user has scrolled down the page
  &.scrolled {
    .mobile-menu .nav {
      opacity: 1;
      pointer-events: all;
    }
    .nav {
      opacity: 0;
      pointer-events: none;
    }
    .nav-bg {
      opacity: 1;
    }
    .logo {
      left: 50%;
      transform: translate(-50%, 0);
      margin-left: 0;
      img {
        margin-left: 0;
      }
    }
  }
}
#dropdown-custom-components {
cursor: pointer;
}
.dropdown-menu .dropdown-menu.show {
left: 60px !important;
}
.dropdown-toggle:after {
  display: none;
  opacity: 0;
}


// Mobile menu
.mobile-menu {
  min-width: 220px;
  a, .dropdown-toggle {
    color: black !important;
    &:focus {
      box-shadow: unset !important;
    }
  }
  .social {
    .justify-content-md-center {
      margin-left: 0;
      margin-right: 0;
    }
    .circle {
      height: 40px;
      width: 40px;
    }
    .col-1 {
      padding: 0;
      min-width: 40px;
      margin: 5px;
    }
    a {
      display: inline-block;
      margin: 5px 5px;
    }
  }
  .dropdown-item {
    font-size: 14px;
  }
}