.webops-about {
  margin-top: 100px;
  * {
    @media (min-width: 768px) {
      max-width: 75%;
    }
  }
  h3 {
    font-size: 34px;
    font-weight: bold;
    margin: 2rem 0;
  }
  .button {
    display: inline-block;
    margin-top: 1rem;
  }
  margin-bottom: 100px;
}

.side-by-side.webops {
  max-width: 90%;
  .button {
    border-color: black !important;
    color: black !important;
  }
  @media (max-width: 767px) {
    max-width: 95%;
    padding: 2rem;
  }
}

.webops-h2 {
  margin-bottom: 0;
}

.webops-h3 {
  margin-bottom: 45px;
}