.services-header {
  margin: 100px auto;
  h2 {
    color: $red;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  p {
    font-size: 1.2rem;
  }
}

.services-cta {
  max-width: 90%;
  margin: 100px auto;
  padding: 160px 0;
  @media (max-width: 767px) {
    width: 100%;
  }
  h2 {
    font-size: 4rem;
    color: white;  
  }
}