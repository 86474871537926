.jumbotron.about {
  @media (max-width: 767px) {
    background-position: 100%;
  }
}

.about-approach {
  margin-top: 100px;
  .card {
    border: none;
  }
  .card-body {
    padding: 24px 0;
  }
}

.about-satisfaction {
  margin-top: 100px;
  text-align: center;
  .jumbotron {
    background-image: url("/img/about/satisfaction.jpg");
    background-size: cover;
    background-position: 0% 30%;
    padding: 100px 0;
    color: white;
  }
  .button {
    display: inline-block;
    font-size: 30px;
    padding: 20px 100px;
    margin: 50px 0 100px 0;
  }
}

.about-commitment {
  img {
    max-height: 340px;
    max-width: 100%;
  }
  .button {
    display: inline-block;
    margin-bottom: 20px;
  }
}

.about-team {
  margin-top: 100px;
  .card {
    margin-top: 40px;
    border: none;
  }
}

.about-partners {
  margin-top: 100px;
  .col-md-3 {
    margin-bottom: 80px;
    text-align: center;
  }
  h2 {
    margin-bottom: 60px;
  }
}

.about-reviews {
  padding: 60px;
  background-color: $red;
  @media (max-width: 767px) {
    padding: 60px 16px;
  }
  h2 {
    color: white;
    font-weight: bold;
    font-size: 40px;
    font-family: Poppins;
  }
  .spacer {
    height: 100px;
  }
  p {
    color: white;
    font-size: 26px;
    line-height: 1.7;
  }
  .author {
    font-size: 14px;
  }
}